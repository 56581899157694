


















































import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref,
} from "vue-property-decorator";

import api from "@/api";
import {
  ProjectSeriesDto,
  ProjectSeriesCreateOrUpdateDto,
  AttachmentHostType,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";

@Component({
  name: "EditProjectSeries",
  components: { SimpleUploadImage },
})
export default class EditProjectSeries extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  defaultData: ProjectSeriesCreateOrUpdateDto = {
    title: undefined,
    titleImagePath: undefined,
    isPublicShow: true,
    sequence: 0,
    id: 0,
  };

  private selectList: any = [
    {
      label: "是",
      value: true,
    },
    {
      label: "否",
      value: false,
    },
  ];

  show = false;
  form: ProjectSeriesCreateOrUpdateDto = { ...this.defaultData };

  get title() {
    if (this.dataId) {
      return `编辑 ${this.form.title}`;
    } else {
      return "新建";
    }
  }
  get hostType() {
    return AttachmentHostType.ProjectSeries;
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog",
      });

      if (this.dataId) {
        await api.projectSeries
          .get({ id: this.dataId })
          .then((res: ProjectSeriesDto) => {
            this.form = res!;
          })
          .finally(() => {
            setTimeout(() => {
              loading.close();
            }, 200);
          });
      } else {
        this.form = { ...this.defaultData };
        //
        setTimeout(() => {
          loading.close();
        }, 200);
      }
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {
    console.log(this.form);

    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.projectSeries.update({
            body: { ...this.form },
          });
        } else {
          await api.projectSeries.create({
            body: { ...this.form },
          });
        }
        this.show = false;
        this.$message.success("更新成功");
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }

  linkRule = {
    title: [
      {
        required: true,
        message: "请输入系列名称",
        trigger: "blur",
      },
    ],
    isPublicShow: [
      {
        required: true,
        message: "是否公开显示未选择",
        trigger: "blur",
      },
    ],
    sequence: [
      {
        required: true,
        message: "请输入显示顺序",
        trigger: "change",
      },
      {
        type: "number",
        message: "显示顺序必须为数字",
        trigger: "change",
      },
    ],
  };
}
